#page-occasion_details {
	&.ooh {
		#to_dish_ingredients{display: none}
		#diarymealcomponents .dishes{display: block}
		.dishes {
			display: none;
		}
		.summary .dishes, .modal-content .dishes{
			display: block;
		}
	}
	.indicator {
		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: 25px;
		background: lightgreen;
		color: black;
		padding: 20px;
		transform: translate(-50%, -50%);
		z-index: 100;
		width: 80%;
	}
	.pointer{
		position: relative;
		background: lightgreen;
		border-radius: 25px;
		top: 10px;
		padding: 5px 20px;
		width: 140%;
		transform: translate(-40%);
		&:after {
			content: '';
			position: absolute;
			top: -20px;
			right: 20px;
			width: 0;
			height: 0;
			border-bottom-color: lightgreen;
			border-top: 0;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-bottom: 20px solid lightgreen;
		}
	}
	/*#contentleftmenu:has(div.curparent) ~ #add_dish{display: none}*/
	.modal-dialog {
		&-centered, &-scrollable {
			height: 100vH;
			margin: 0;
			overflow: hidden;
		}
		.modal-content{
			height: 100vH;
			border-radius: 0;
			.nextcoursebtn{display: none}
			.completeoccasionbtn{display: none}
			.cancelbuttons{display: none}
		}
	}
	.guide-link{margin-bottom: 1.5rem}
	#contentleftdishrecipe > div{
		border-bottom: 1px solid $lightgrey;
	}
	#contentleftdishrecipe > div:last-of-type{
		border-bottom: 0;
	}
	.dishrecipe{
		font-size: 0.875rem; 
		cursor: pointer;
		line-height: 1.818;
		&:hover, &.active{
			background-color: rgba(0,182, 255, 0.2);
		}
	}
	#categorystuff {
		input,
		input + span{cursor: pointer}
		input[disabled],
		input[disabled] + span {cursor:not-allowed}
	}
	#product_search, #dish_search {
		.col-form-label{
            font-size: 0.825rem;
		}
		.form-check-label{
            font-size: 0.825rem;
        }	
	}	
	#searchbtn{
		text-align: left;
		padding-right: 35px ;
		padding-left: floor(calc(($grid-gutter-width)/2));
		position: relative;
		::after{
			content: '';
			background-image: url(../../img/arrow1.svg);
			background-repeat: no-repeat;
			transform: rotate(90deg);
			position: absolute;
			top: 7px;
			width: 9px;
			height: 15px;
			display: inline-block;
			right: floor(calc(($grid-gutter-width)/2));				
		}	
	}
	#ajaxTreeView {
		&.curparent{
			.mobile{
				&:not(.curparent){
					display: none;
				}				
			}
		}
		.mobile{	
			&[data-item="Cupboard"] {
				margin-bottom: 3px;
				> div > .subcatnode {
					background-color: #00B6FF;
				}	
			}
			> .category {
				> div.subcategory {		
					display: none;			
					.subcategory {background-color: #00B6FF; color:#fff; padding: 0 8px;}
				}
				&.curparent{
					&~ ._root{
						visibility: hidden;
						display: none;						
					} 
					.subcatcurrent > * {
						visibility: hidden;
						display: none;
					}
					.subcatcurrent:has(> .subcatnode.current) > * {
						display: block;
						visibility: visible;
					}					
					.subcatcurrent > .subcatnode,
					.subcatcurrent > *:has( .current) {
						visibility: visible;
						display: block;
					}
					.subcatcurrent ~ .product,
					.subcatcurrent ~ .subcategory,
					.product:has(~ .subcatcurrent),
					.subcategory:has(~ .subcatcurrent){
						visibility: hidden; 
						display: none;
					}
					
					> .subcatnode.current ~ .subcategory{
						visibility: visible; 
						display: block;
					}
					
					.category .subcatnode .category{
						visibility: visible;
						width: 100%;
						text-align: center;
						display: inline-block;
					}
					> .subcategory {visibility: visible; display: block; margin: 2px 0px;}
					> .subcategory:not(.curparent), ~ .subcategory {visibility: hidden; display: none}
					> .subcategory.subcatcurrent {visibility: visible; display: block;}
					> .subcatcurrent ~ .subcategory {visibility: hidden; display: none;}
				}
				.subcatnode{ background-color: #0060FF;}
				.title01_left{color:#fff; padding: 0 8px;text-align: center;}
    		}
			.subcategory .subcatnode{
				&.current{background-color: #00B6FF;margin-left: 0}
				background-image: none !important;
				background-color: #00B6FF;
				text-align: center;
				padding: 8px;
			}
			.subcategory .product { 
				padding-left: 0 !important;
				color: $body-color;
				.subcatnode{
					background-image: none !important;
					background-color: #fff;
					border: 1px solid #000;
					text-align: center;
					padding: 8px;
				}
				/*
				& + div.subcatcurrent {
					display: none;
				}
				*/
			}
		}
		div.category { padding: 0; margin: 0; border-bottom: 1px solid $lightgrey; }
		span.category [class^="title"] {
		  color: $lightblue;
		  font-size: 0.875rem;
		  font-weight: bold;
		  font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
		  text-transform: uppercase;
		}
		div.subcategory {
		  padding: 0;
		  margin: 0;
		  cursor: pointer;
		}
		div.subcatcurrent {
		  background: white;
		}
		span.subcategory {
		  font-size: 0.9rem;
		  text-decoration: none;
		  white-space: normal;
          word-break: break-word;
		  display: inline-block;
		  line-height: 0.9rem;
          margin: 0.5rem 0;		  
		}
		.subcategory .subcatnode {
		  background: transparent url(../../img/arrright-blue.png) no-repeat 0 50%;
		  padding-left: 10px;
		  &.current {
			background: transparent url(../../img/arrdown.png) no-repeat 4px 50%;
			margin-left: -4px;
			padding-left: 14px;
		  }
		  .parent {
	        background: url(../../img/arrdown-blue.gif) no-repeat 0 50%;
	      }
		}  
		.product .subcatnode {
			background: transparent;
		}
		.product .subcatnode.current,
		.product .subcatnode:hover,
		.subcategory .subcatnode:hover {
			margin-left: 0px;
			background-color: rgba(0,182, 255, 0.2);
		}
		.product .subcatnode.current,
		.product .subcatnode:hover {
		  background-image: none;
		  background: rgba(0, 182, 255, 0.35);		  
		}
		.subcategory .subcatnode:hover {
		  background-position: 0px 50%;
		}

		div.subcatnode:hover,
		div.subcatcurrent .subcatnode:hover,
		div.subcatcurrent .parent,
		.subcatnode.current {
			cursor: pointer;
		}
		.product {
		  font-size: 0.8rem;
		  cursor: pointer;
		  line-height: 0.8rem;
		  margin: 0.5rem 0;		  
		}
		div.atv_treenode { 
			padding-left: 0;
			&.subcategory > .atv_treenode {
				padding-left: 10px;
			}
		}
		._root{border-bottom: 1px solid $lightgrey}
		.leaf { }
		.rootleaf { padding: 0; }
	}
	.pager{
		a{ color: $lightblue}
		span{ color: $darkblue}
	}
	#breadcrumbs{color: $lightblue}
	#used_dishes{
		list-style: none;
		li{
			cursor: pointer
		}
	}
	#product_content, #dish_content {
		> div{
			padding: floor(calc(($grid-gutter-width)/3));
		}
		h3{font-size: 1.87rem}
		.pager{
			display: flex;
    		flex-direction: row;
    		justify-content: space-between;
		}
		.pager, .dishrecipe {
			margin-bottom: 0;
			padding: 0;
		}
		.active{z-index: 5}
		.productsheader {
			padding-left: 0;
			padding-right: 0;
			h3{
				padding: floor(calc(($grid-gutter-width)/5)) floor(calc(($grid-gutter-width)/2));
				background: $darkblue;
				color: #fff;			
			}	
		}

		.product_item{
			display: inline-block;
			padding: floor(calc(($grid-gutter-width)/3));
			> div {
				max-width: 50%;
				width: 50%;
				float: left;
				padding-left: floor(calc(($grid-gutter-width)/6));
				span.product{
					/*max-height: 80px;*/
					overflow: hidden;
					display: block;
					text-overflow: ellipsis;
					font-size: 0.875rem;				
				}
			}
			.product_image {
				padding-left: 0;
				float: left;
				width: 50%;
				position: relative;
				height: 0;
				padding-bottom: 55%;
				height: 0;
				overflow: hidden;
				max-width: 100%;				
				img{
					max-width: 100%;
					object-fit: contain;
					width: 100%;
					top: 0;
					left: 0;
					position: absolute;
					max-height: 100%;
				}
			}
			.plus {
				background: $lightblue;
				width: 20px;
				height: 20px;
				margin-top: 5px;
				cursor: pointer;
				a{line-height: 20px; position: absolute}
				.plus-img{
					margin: 4px;
					width: 12px;
					height: 12px;					
				}
            }
			&.noimage{
				padding: 5px 0;
				> div:not(.plus) {
					max-width: none;
					width: 90%;
				}
				.plus {
					margin-top: 0;
					.plus-img{
						margin: auto;
					}				
				}
				span.product{
					line-height: 20px;
					width: 100%;
					max-width: none;
					color: black;
					text-align: left;				
				}
			}
		}
	}
	.diary-main{
		padding-top: floor(calc(($grid-gutter-width)/2));
		padding-bottom: floor(calc(($grid-gutter-width)/2));
	}
	.contentleft{
		.btn{
			width: 100%;
			text-align: left;
			padding: 4px 35px 4px floor(calc(($grid-gutter-width)/2));
			position: relative;
			display: inline-block;
			span{
				white-space: normal;
			}
			::after{
				content: '';
				background-image: url(../../img/arrow1.svg);
				background-repeat: no-repeat;
				transform: rotate(90deg);
				position: absolute;
				top: 7px;
				width: 9px;
				height: 15px;
				display: inline-block;
				right: floor(calc(($grid-gutter-width)/2));				
			}
			&#otherproduct, &#to_dish_ingredients{
				::after{
					transform: rotate(90deg);			
				}	
			}
		}
		#add_dish{
			.btn{
				::after{
					transform: rotate(-90deg);
					top: 6px;
				}
			}		
		}
	}
	.contentleft > * {
		margin-bottom: floor(calc(($grid-gutter-width)/6));
	}
	.autocomplete {
		ul{
			list-style: none;
			padding: 0 floor(calc(($grid-gutter-width)/2));
			li.addnew{margin: floor(calc(($grid-gutter-width)/6)) 0 }
		}
		background: #ededed;
		display: none;
		position: absolute;
		z-index: 10;
		&--visible {
			display: block;
		}
		.searchhighlight{font-weight: bolder;font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;}
		.form-control{width: initial}
		&__list {
		list-style: none;
		margin:0;
		padding:0;
		}

		&__item {
		color: #333;
		padding: .5em;
		border-bottom: 1px solid #fff;

		&--selected {
		  background-color: #333;
		  color: #fff;
		}
		}

		&__highlight {
			display: inline;
			margin:0;
			font-weight: bold;
			font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
		}
	}
	.contentmiddle {
		h3{margin-top: floor(calc(($grid-gutter-width)/3));}
		.row{padding: 0!important;}
	}
	.diary-side, .modal-body{
		li > span{white-space: normal;}
		.btn{
			text-align: left;
			span{
				background-color: inherit;
				white-space: normal;
			}
		}
		.deleteicon {
			img{ 
				width: auto;
				height: 0.8rem;
				transform: initial !important;		
			}
		}	
		[data-ff-action="delete_dish"],
		[data-ff-action="delete_product"]{
			float: right;
			color: #fff;
			background: $darkblue;
			padding: 0 3px;
			cursor: pointer;
			position: relative;
			z-index: 9;			
		}
		[data-ff-action="edit_dish"] {
			cursor: pointer;			
		}
		&.clone{
			&.pot, &.dish{
				.dishCounter{
					.fill{fill: #fff}
					&:before, &:after{
						content: '';
						position: absolute;
						width: 100%;
						background-repeat: no-repeat;
						width: 100%;
						height: 100%;
						display: block;						
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
					}
				}		
			}
			&.pulse {
				.dishCounter {
					&:before{
						transform: scale(1);
						animation: pulse-white 2s infinite;
					}
					&:after{
						background-image: url('../../img/mobile/flames.svg');
						width: 40%;
						top: 30px;
						left: 10px;	
						transform: scale(1);
						animation: pulse-flame 2s infinite;											
					}					
				}
			}
			&.pot{
				.dishCounter{
					&:before{
						background-color: #fff;
						-webkit-mask-image: url('../../img/mobile/pot.svg');
						mask-image: url('../../img/mobile/pot.svg');
					}
					span{
						left: 30%;
						top: 8%;
					}
				}
			}
			&.dish{
				.dishCounter{
					&:before{
						background-color: #fff;
						-webkit-mask-image: url('../../img/mobile/plate.svg');
						mask-image: url('../../img/mobile/plate.svg');
					}
					&.after{
						display: none;
					}
					span{
						left: 45%;
						top: 20%;
					}
				}	
			}
			.dishCounter{
				position: absolute;
				right: 10px;
				top: 2px;
				width: 80px;
				height: 80px;
				span{
					position: relative;
				}
			}	
			.dishesModal{
				display: none;
				z-index: 90;
				position: relative;
				.selected{
					background: $lightblue;
				}				
			}
		}
		#diarymealcomponents, .diarymealcomponents{
			> ul.products{
				list-style: none;
				> li{
					padding-left: floor(calc(($grid-gutter-width)/2));
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;					
				}
			}
			ul{
				list-style: none;
				padding: 0;
				li{
					margin-bottom: 2px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					position: relative;
					z-index: 5;
					&.currentdish {
						background-color: rgba(0,182,255, 0.2);
						margin-bottom: 0;
						> span {
							background: $lightblue;
							padding: 4px 0 4px floor(calc((($grid-gutter-width)/3)*5)) ;
							margin: 0;
							color: #fff;
							width: 100%;
							display: block;
							position: relative;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;							
						}
						ul{
							padding-left: floor(calc((($grid-gutter-width)/3)*5));
							/*background: #fff;*/
						}
					}
										
				}
				&.dishes{
					> li:not(.currentdish){
						background: $lightblue;
						padding: 4px 0 4px floor(calc((($grid-gutter-width)/3)*4));
						margin: 0;
						color: #fff;
						width: 100%;
						display: block;
						position: relative;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;						
						a{
							color: inherit
						}
						&::before{
							position: absolute;
							left: 4px;
							top: 6px;
							content: "";
							background-image: url(../../img/arrow1.svg);
							background-repeat: no-repeat;
							width: 8px;
							height: 15px;
							display: block;
						}
					}	
				}
			}
		}	
		.btn {
			padding: 4px 35px;
			span{
				position: relative;
				display: block;
				background-color: inherit !important;
				img{
					width: 8px;
					align-self: center;		
					position: absolute;
					left: -22px;
					top: 4px;
				}	
			}	
		}
		#nextcoursebtn .btn img{
			left: initial;
			right: -15px;
			top: 4px;
			height: 11px;
			transform: rotate(-90deg);
		}
	}
	ul.dishes ul.products span {
		padding: 0 0 0 30px !important;
	}
	.courses{
		list-style: none;
		padding: 0;
		ul{list-style: none; padding: 0 0 0 0; }
		> li{
			margin-bottom: 2px;
			cursor: pointer;
			> span{
				background: $superdarkgrey;
				padding: 4px floor(calc(($grid-gutter-width)/4)) 4px floor(calc((($grid-gutter-width)/4)*5)) ;
				margin: 0;
				color: #fff;
				width: 100%;
				display: block;
				position: relative;
				img{
					width: 8px;
					align-self: center;		
					position: absolute;
					left: 4px;
					top: 6px;
				}	
			}	
			&.current {
				> span{
					img{
						width: 8px;
						align-self: center;		
						position: absolute;
						left: 6px;
						top: 6px;
						transform: rotate(90deg);
					}
				}	
				.dishes {
					li{
						&.currentdish{
							padding: 0;
						}
					}
					#dishcompletebtn{
						margin: floor(calc(($grid-gutter-width)/3)) floor(calc((($grid-gutter-width)/3)*2));
					}
				}
				span{
					background-color: $completegreen;
					width: 100%;
					padding: 4px 4px 4px 20px;
				}	
			}			
			.currentdish{
				.products{
					padding: 0 0 0 12px;
				}
			}
			.clearfix{
				background-color: $lightgrey;
			}
			.currentcourse{
				background: #fff;
				padding: 2px 0 2px 0px;
				margin: 0;
				color: #fff;
				ul.products li{
					padding: 0 0 0 floor(calc((($grid-gutter-width)/3)*5));
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					position: relative;
					z-index: 5;
				}
				.currentdish ul.products li{
					padding-left: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;					
				}
				&.current{
					font-weight: bold;
					font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
				}
				img{		
					transform: rotate(90deg);
				}
			}	
		}
	}
	.modal-dialog{
		.course-label{
			background-color: $lightblue; 
			padding: floor(calc(($grid-gutter-width)/4)) floor(calc(($grid-gutter-width)/4)) floor(calc(($grid-gutter-width)/4)) floor(calc((($grid-gutter-width)/4)*5)); 
			color: #fff;
			position:relative;
			&::before{
				position: absolute;
				left: floor(calc(($grid-gutter-width)/4));
				top: floor(calc(($grid-gutter-width)/2));
				content: "";
				background-image: url(../../img/arrow1.svg);
				background-repeat: no-repeat;
				width: 8px;
				height: 15px;
				display: block;
			}
		}
		.dish{
			background-color: $lightgrey;
		}
		.course-edit {
			span{background-color: $darkblue; padding: 0;}
			position: relative;
			width: initial;
			padding: floor(calc(($grid-gutter-width)/4)) floor(calc(($grid-gutter-width)/4)) floor(calc(($grid-gutter-width)/4)) floor(calc((($grid-gutter-width)/4)*5));
			&::before{
				position: absolute;
				left:floor(calc(($grid-gutter-width)/4));
				top: floor(calc(($grid-gutter-width)/2));
				content: "";
				background-image: url(../../img/arrow1.svg);
				background-repeat: no-repeat;
				width: 7px;
				height: 13px;
				display: block;
				transform: rotate(180deg);
			}
		}	
		.courseitems{
			padding: 0;
			background: $lightgrey;
			clear: both;
			ul{
				margin: floor(calc(($grid-gutter-width)/3)) floor(calc(($grid-gutter-width)/2)) floor(calc(($grid-gutter-width)/3)) 0; 
				li{
					font-size: 0.875rem
				}
			}
		}
	}
	ul {
		li {
			width: 100%;
			&.clearfix:before{ display: none}
		}
	}
	.need_answers {

		span.matrix-row-label{
			padding: 3px floor(calc(($grid-gutter-width)/2)) 0px;
			background: $lightgrey;
			display: block;
			margin: 2px 0;
			/*white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;*/		
		}
		.row{
			padding: 0;
			margin: 0 !important;
			display: flex;
			width: 100%;
			div[class*='col-']:first-of-type{
				padding: 0;
			}
			div[class*='col-']:not(:first-of-type){
				flex: 1;
				width: max-content;
				flex-shrink: 1;
				flex-basis: 0;
				padding: 0;
				&:empty{display: none}
				label{margin: 0}					
			}
			&.headrow{
				margin-top: floor(calc(($grid-gutter-width)/2)) !important;
				font-weight: bold;
			}
			&.empty{height:5px;}
		}
		div[class*='col-']:not(:first-of-type){
			text-align: center;
			label{
				background: $lightgrey;
				display: block;
				margin: 0;
				&:hover, &.checked{
					background: $darkgrey;
					cursor: pointer;
				}
				input[type="radio"], input[type="checkbox"]  {
					&:checked ~ label {
						background-color: $darkgrey;
					}
				}
			}
			label[for^='answer']:not([for*='whw']) input[type="checkbox"] {
				display: none;
			}
		}			
		.headrow div[class*='col-']:not(:first-of-type){
			text-align: center;
			white-space: pre-wrap;
			word-break: break-word;
			word-wrap: break-word;
		}
		div:not(.empty) div[class*='col-'].noinputs{
			&:first-of-type, &:first-of-type span {
				background: $lightblue;
				color: #fff;
				padding-left: floor(calc(($grid-gutter-width)/2));
				padding-right: 5px;
			}		
		}	
		div.headrow {
			div[class*='col-']:first-of-type {
				text-align: center;
			}
		}	
		div:not(.headrow, .empty) {
			label{
				&:hover{
					cursor: pointer;				
				}	
			}
			div[class*='col-']:last-of-type {
				padding-right: 0;
			}		
			div[class*='col-']:first-of-type {
				padding: 0;
				span{
					padding: 3px floor(calc(($grid-gutter-width)/2)) 0px;
					background: $lightgrey;
					display: block;
					margin: 2px 0;
					/*white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;*/
				}
			}
			div[class*='col-']:not(:first-of-type, .noinputs) {
				width: 100%;

				label{
					background: $lightgrey;
					display: block;
					margin: 2px;
					height: 95%;
					&:hover, &.checked{
						background: $darkgrey;
						cursor: pointer;
					}
					input[type="radio"], input[type="checkbox"]  {
						&:checked ~ label {
							background-color: $darkgrey;
						}
					}				
				}
			}
		}	
	}	
	.tab_question {
		.answerpanel{
			label{
				background: $lightgrey;
				display: block;
				margin: 2px;
				&:hover{
					background: $darkgrey;
					cursor:pointer;
				}
				input[type="radio"], input[type="checkbox"]  {
					&:checked ~ label {
						background-color: $darkgrey;
					}
				}				
			}
		}
		.answerpanel{
			margin-top: floor(calc(($grid-gutter-width)/2));
			padding-right:0;
		}
		.items {
			margin-top: floor(calc(($grid-gutter-width)/2));
			&:hover{cursor: pointer}
			ul span{
				display: block;
				/*white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;*/
			}
			.selected{
				font-weight: 800;
				font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
			}
			.completed {
				position: relative;
				&:after{
					content: "";
					display: inline-block;
					width: 20px;
					height: 15px;
					top: 3px;
					position: absolute;
					right: 5px;
					background-size: cover;
					background: transparent url(../../img/bluetick.svg) no-repeat 50% 50%;	
				}
			}
			.courses {	
				li li {
					&.selected span{font-weight: bold; font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;}
					span{
						background-color: $lightgrey; 
						color: #000;
						display: block;
					}
				}
				> li {
					margin-bottom: floor(calc(($grid-gutter-width)/2));
					span{
						background-color: $lightblue; 
						padding: 3px 8px 3px floor(calc((($grid-gutter-width)/4)*5)); 			
						&::before{
							position: absolute;
							left: floor(calc((($grid-gutter-width)/4)));
							top: floor(calc((($grid-gutter-width)/2)));
							content: "";
							background-image: url(../../img/arrow1.svg);
							background-repeat: no-repeat;
							width: 8px;
							height: 15px;
							display: block;
						}
					}
				}	
			}	
		}	
	}	
	@media (pointer: fine) {
		.tab_question {			
			.items {
				ul span{
					max-width: 90%;
				}
			}
		}			
		.need_answers {
			.row{
				div[class*='col-']:first-of-type{
					padding: 0;
					flex-grow: 0;
					flex-shrink: 0;
					flex-basis: 20%;
					max-width: 20%;
				}
				div[class*=col-]:not(:first-of-type){
					&:empty{ display:block }
					input[type='checkbox'] {
						display: inline-block !important;
					}	
				}	
				div:not(.headrow, .empty) {					
					div[class*='col-']:not(:first-of-type, .noinputs) {
						&:empty{display: block}
						label{
							margin: 2px;
							input[type='checkbox'] {
								display: inline-block;
							}	
						}
						label[for^='answer']:not([for*='whw']) input[type="checkbox"] {
							display: none;
						}
					}	
				}		
			}
		}		
		.modal-dialog{
			.modal-content{
				height: initial;
			}
			margin: 1.75rem auto;
			&-centered {
				min-height: calc(100% - 1rem);
			}
			&-scrollable {
				height: calc(100% - 1rem);
			}
			.summary{
				margin-top: floor(calc(($grid-gutter-width)/2));
			}
		}
	}	
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }	
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-flame {
	0% {
	  transform: scale(0.95);
	}
	70% {
	  transform: scale(1);
	}	
	100% {
	  transform: scale(0.95);
	}
  }