$lightblue: #00B6FF;
$darkblue: #0060FF;
$completegreen: #65ae54;
$lightgrey: #ededed;
$darkgrey: #dadada;
$superdarkgrey: #999999;
$shadowcolor: #aaaaaa;
$grid-gutter-width: 16px !default;

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}
@import "fonts";

*,
*::before,
*::after {
  box-sizing: inherit;
}

*{
    margin: 0;
    padding: 0;						   
}

html {
    height: auto;
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;	
}

/* !
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "bootstrap5/functions";
@import "bootstrap5/variables";
@import "bootstrap5/mixins";
@import "bootstrap5/utilities";

// Layout & components
@import "bootstrap5/root";
@import "bootstrap5/reboot";
@import "bootstrap5/type";
@import "bootstrap5/images";
@import "bootstrap5/containers";
@import "bootstrap5/grid";
@import "bootstrap5/tables";
@import "bootstrap5/forms";
//@import "bootstrap5/buttons";
@import "bootstrap5/transitions";
@import "bootstrap5/dropdown";
@import "bootstrap5/button-group";
@import "bootstrap5/nav";
@import "bootstrap5/navbar";
//@import "bootstrap5/card";
@import "bootstrap5/accordion";
@import "bootstrap5/breadcrumb";
@import "bootstrap5/pagination";
@import "bootstrap5/badge";
@import "bootstrap5/alert";
@import "bootstrap5/progress";
@import "bootstrap5/list-group";
@import "bootstrap5/close";
@import "bootstrap5/toasts";
@import "bootstrap5/modal";
@import "bootstrap5/tooltip";
@import "bootstrap5/popover";
@import "bootstrap5/carousel";
@import "bootstrap5/spinners";
@import "bootstrap5/offcanvas";

// Helpers
@import "bootstrap5/helpers";

// Utilities
@import "bootstrap5/utilities/api";

/* // Core variables and mixins
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
//@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
//@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";
 */

[data-ff-toggle="action"], label[for]{
	&:hover{cursor: pointer}
}
.helpicon{
	display: none;
	float: right;
	margin: 0 5px; 
    width: 15px;
    height: 16px;
	z-index: 5;
	&::before{
		background-image: url('../../img/question-mark-icon.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		width: 7px;
		height: 12px;
		display: block;		
		content: ' ';
	}
}
.helpshown .helpicon, .helpshown .helpicon.hidden{
    display: inline-block!important;
    margin-right: -20px;
    margin-top: 10px;
}
#help-button {
	&:hover{cursor: pointer}
}
.col-md-2_5{
	width: 20.833%;
}

.sr-only{
    position:absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0,0,0,0);
    border:0;
    &-focusable:active,
    &-focusable:focus{
        position:static;
        width:auto;
        height:auto;
        margin:0;
        overflow:visible;
        clip:auto
    }
}
a {
    text-decoration: none;
}

body{
    font-family: 'KantarBrown-Regular', Arial, sans-serif;
    display: flex;
    flex-direction: column;
	min-height: 100%;
	> * {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-basis: auto;
	}
}

.header {
    height: 110px;
    width: 100%;
    max-width: 1400px;	
    &::after{
        content: '';
        position: absolute;
        top: 108px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background: rgb(178,131,0);
        background: linear-gradient(to right, rgba(178,131,0,1) 30%, rgba(255,255,106,1) 68%, rgba(232,187,62,1) 100%);
    }	
}

.mobile .header{
    &::after{
        height: 0;
        background: transparent;
    }	
}

.main {
    flex: auto;
	padding-top: floor(calc(($grid-gutter-width)*2));
}

.footer {
    height: 85px;
}

.fd-input{
    margin-left: 10px;
    padding: 4px;
    width: 70%;
}

.btn{
    background: $lightblue;
    font-family: 'KantarBrown-Bold';
    border: none;
    padding: .4em 1em;
    color: #fff;
	position: relative;
    display: inline-block;
}

.buttons-final .btn{
    padding: 7px 30px 7px 20px;
    margin: 0 40px 0 0; 
    img{
		position: absolute;
		left: initial;
		right: 15px;
		top: 8px;
		height: 15px;   
	}	   
}

#page-contact_us {
	label{color: black;}
	&.darkmode label{color: white;}
}	
.btn-dark{
    background: $darkblue;
}

p {
	display: block;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 0;
}

button{
    font-family: 'KantarBrown-Bold'; 
    border: none;
    padding: .2em 1.6em;
    color: #fff;
	font-size: 1rem;
    &.dark-blue{
        background: $darkblue;
    }

    &.light-blue{
        background: $lightblue;
    }
}

b, strong {
    font-family: 'KantarBrown-Bold'; 
}

.inline{
    display: flex;
    flex-direction: row;

    &.options{
        justify-content: space-between;
    }
}

input{
	&:focus{
		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&::placeholder {
			opacity: 0;
		}
	}
}

.form-group {
    margin-bottom: 1rem;
}

/*
	
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.form-control{
	display: block;
	height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
	padding: $padding-base-vertical $padding-base-horizontal;
	font-size: $font-size-base;
	line-height: $line-height-base;	
    background-clip: padding-box;
}
*/
.col-form-label{
	padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.main .container.days-completed .days1,
.main .container.days-completed .days1 {
	/*position: absolute;
	right: 0;*/
	h3 {
		text-align: right;
	}	
}

.main .container .days1{	
	h3 {
		color: $darkblue;
		margin: 0;
	}
	.colflex1,
	.colflex {
		span {
			background-color: $lightblue;
			margin: 2px;
			padding: 5px 4px;
			color: #fff;
			display: inline-block;
		}
		.tick {
			width: 26px;
			background-color: $completegreen;
			padding: 8px 4px;
			margin: 2px;
		}
	}
}

.main{
	padding-top: 110px;	
}

.main, .header{
    display: block;
	.diary-body {
		display: block;
		padding: floor(calc(($grid-gutter-width)/2)) 0;
        /*margin-top: 20px;*/
	}
    .sub-wrapper-menu {
        width: 350px;
        left: 36%;
        margin: auto;
        z-index: 99;

        .menu-container {
            width: 350px;
            background: $darkblue;
            margin: auto;
            box-shadow: 3px 4px 15px 0px #888888;
            .menu-container1 {
                background: $lightblue;
                width: 100%;
				padding-top: floor(calc(($grid-gutter-width)*2))			
            }
            .menu-title {
                text-align: center;
				img {
					width: 85px;
					text-align: center;
					padding: 25px 0 10px 0;
				}
				h2 {
					color: #ffffff !important;
					text-align: center;
					padding-bottom: 30px;
				}
			}
            .menu-middle {
                // background: #00B6FF;
                padding: 8px 0 5px 0;
                width: 265px;
                text-align: left;
                margin: auto;
                label {
                    color: #ffffff !important;
                }
            }
        }
    }
    .side-days {
		background-color: $darkblue;
		padding: 5px;
		width: 100%;
	    h3, p {
            color: #ffffff !important;
        }
        h3 {
            margin: floor(calc(($grid-gutter-width)/3)) 0 0 0;
        }
        p {
            margin: 0  0 floor(calc(($grid-gutter-width)/3)) floor(calc(($grid-gutter-width)/2));
        }
		img{
			width: 8px;
			margin: 0 0px 5px 0px;			
			align-self: center;
		}
    }
    .sub-wrapper, .section {
        position: relative;
		z-index: 99;			
        &.shadow {
            &::before {
                content: '';
                box-shadow: none !important;
            }
        }
        &.spine {
            padding: 0;
            .popup-1 {
                position: absolute;
                background: #ffffff;
                width: 95%;
                margin: 0;
                padding: 0 10px 10px 10px;
                box-shadow: 3px 3px 15px 3px $shadowcolor;
                padding-bottom: 50px;
                .test {
                    font-size: 48px;
                    color: $lightblue;
                    text-align: right;
                    cursor: pointer;
                }
                .popup-head {
                    width: 100%;
                    background: $darkblue;
                    padding: 12px 25px 12px 25px;
                    color:#ffffff !important;
                    margin-bottom: 12px;
                    #pu-right {
                        float: right;
                        text-align: right;
                    }
                }
            }			
            #help-button h4 {
				position: absolute;
				right: 42px;
				top: -32px;
				font-size: 18px;
				color: $darkblue;
			}
			#help-icon, .help-icon {
				height: 36px;
				width: 36px;
				position: absolute;
				right: 0;
				top: -44px;
				&:hover{cursor: pointer;}
			}
			.diary-title{
				position: relative;
				/*margin-top: 10px;*/
                padding-top: 0;
				/*top: -25px;
				max-width: 80%;
				height: 60px;*/
                flex-wrap: wrap;
                display: inline-flex;
                /*margin-bottom: 25px;*/
                .break{
                    flex-basis: 100%;
                    height: 0;               
                }
			}			
			.blue-box {
				position: absolute;
				background-color: $darkblue;
				padding: 20px 30px 30px 30px;
				width: 350px;
				right: 0;
				display: none;
				color: #fff;
				z-index: 99;
				a{color:#fff}
				.help-header a{
                    float: right; 
                    margin: 0 0 5px 5px; 
                    cursor: pointer
                }
				h4 {
					margin-top: 0;
					margin-bottom: 10px;
				}
				.help-body{
					color:#fff; 
					p {
						color: inherit;
						padding-left: 0;
					}
				}
			}
            
        }

        #hello {
            color: $darkblue;
        }
        .colflex1,
        .colflex {
            display: flex;
            margin-bottom: 4px;
            .grey {
                background: #c4c4c4 !important;
            }
            .flex {
                flex:1;
                align-self: center;
                margin: 2px;
                background: #e6e6e6;
                padding: 4px;
                height: 100%;
                font-size: 0.84em;
            }							   
            .left {
                flex:1;
                margin-right: 10px;
                button {
                    background: $lightblue;
                    padding: 6px 12px 6px 12px;
                }
                .food-title {
                    width:100%;
                    display: block;
                    background: $lightblue;
                    padding: 4px 4px 4px 20px;
                    color: #fff;
                }
                .food-name {
                    width:100%;
                    display: block;
                    background: #e6e6e6;
                    padding: 4px 4px 4px 20px;
                }
                #all1 {
                    margin-bottom: 12px;
                    text-align: center;
                    font-family: 'KantarBrown-Light';
                }
                .edit {
                    position: absolute;
                    background: $darkblue;
                    right: -80px;
                    padding: 4px 12px 4px 12px;
                    color: #fff;
                    text-align: center;
                }
            }
            .right {
                flex:1;
                margin-left: 10px;
                .next-pu {
                    position: absolute;
                    bottom: 10px;
                    right: 25px;
                    display: flex;
                    color: #ffffff;
                    .next {
                        background: $darkblue;
                        padding: 3px 8px 3px 8px;
                        margin-left: 10px;
                        width: 100px;
                        text-align: center;
                    }
                    .previous {
                        background: $darkblue;
                        padding: 3px 8px 3px 8px;
                        margin-right: 10px;
                        width: 100px;
                        text-align: center;
                    }
                }
                #help {
                    position: absolute;
                    right: 42px;
                    top: 8px;
                    font-size: 18px;
                    color: $darkblue;
                }
                #help-icon, .help-icon {
                    height: 36px;
                    width: 36px;
                    position: absolute;
                    right: 0;
                    top: -5px;
					&:hover{cursor: pointer;}
                }
                .blue-box {
                    position: absolute;
                    background-color: $darkblue;
                    padding: 20px 30px 30px 30px;
                    width: 350px;
                    right: 0;
                    display: none;
                    color: #fff;
                    z-index: 99;
                    h3 {
                        margin-bottom: 10px;
                    }
                }
                button {
                    background: $lightblue;
                    padding: 6px 24px 6px 12px;
                }
                ol {
                    margin-left: 18px;
                    font-weight: bold;
                    font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
                    li {
                        margin: 4px 0 4px 0;
                    }
                }
			}	
            .long-list {
                display: block;
                text-align: center;
                width: 80px;
                background: #e6e6e6;
                margin: 2px;
                width: 100px;
                label {
                    font-size: 0.8em;
                }			 
            }
        }
		.diary-side{
			background-color: $lightgrey;
			padding: floor(($grid-gutter-width));
		}
        .diary-title {
            margin-left: 0; // 12px;
            padding: 12px 0 0 0;
            flex-direction: row;
            display: flex;
            width: 100%;
            .diary-icon {
                height: 50px;
                width: 50px;
                margin-right: 8px;
                flex: 0 0 auto;
                width: 10%;
            }
            .sub-title, p {
				color: $lightblue;
				align-self: center;
				margin-top: 0;
                flex: 0 0 auto;
                width: 70%;
			}
            p {
                width: 95%;
			}
        }

        h1 {
            margin: 0 0 10px 0;
        }

        .diary {
            position: relative;
            width: 100%;
            p,h2 {
                padding-left: 5px;
                margin: 8px 0 8px 0;
            }
			.tick {
				width: 18px;
				margin: 0 4px 0 4px;
            }
            #search {
                height: 24px;
                margin-left: 10px;
                align-self: center;
            }
            .find {
                background: #0060FF;
                padding: 2px 12px 2px 12px;
                margin: 0;
                align-self: center;
                text-align: center;
                margin-left: 15px;
                color: #fff;
            }

            input[type=checkbox] {
                height: 12px;
                width: 12px;
                margin: 0 5px 0 5px;
            }

            .diary-right {
                position: absolute;
                background: #e6e6e6;
                top: 0;
                right: 0;
                width: 26%;
                padding-left: 15px;
            }

            #smallfont {
                font-size: 0.7em;
            }

            .dropdown {
				position: absolute;
                h3 {
                    font-size: 1em;
                    margin-top: 3px;
                    cursor: pointer;
                }
                li {
                    font-size: 0.7em;
                    list-style-type: none;
                    &:hover {
                        background: rgba(0,182,255,0.35);
                        padding: 2px;
                    }
                }
            }

            .diary-middle {
                width: 73%;
                position: absolute;
                top: 70px;
                height: 77%;
                .products {
                    margin-left: 178px;
                    margin-top: -25px;

                }
            }
            .day-title {
                color: $darkblue;
                margin: 8px 0 8px 0;
            }
            #diary-title {
                font-weight: bold;
                font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
                font-size: 1.1em;
            }
            .days-comp {
				a{
					background: $darkblue;
					padding: 8px 12px 8px 12px;
					width: 190px;
					text-align: center;
					color: inherit;
					&:hover{color: inherit;}
				}	
                color: #fff;
                position: absolute;
                bottom: -60px;
                right: floor(calc(($grid-gutter-width)/2));
                
            }			 
            .days {
                position: relative;
                display: flex;
                width: 100%;
                margin-top: 12px;
				border-bottom: 0;
                .meal {
                    label > span:before {
                        content: "\a";
                        white-space: pre;
                    }
					&.complete{
						.feed-box{
							background-color: $completegreen;
						}	
					}
                    position: relative;
                    flex: 1 1 0; flex-grow: 1; flex-shrink: 1; flex-basis:0px;
                    text-align: center;
                    margin-right: 5px;
                    min-height: 180px;
                    padding-top: 8px;
                    text-align: center;
					width: 100%;
					a{
						display: block; 
						width: 100%; 
						height: 100%;
						&:hover{
							text-decoration: none
						}
					}
                    &:last-of-type {
					   margin-right: 0;
					}
                    span {
                        position: relative;
                        text-align: center;
                        padding: 5px;
                        &.catlabel{
                            a{color: inherit}
                            &.complete{
                                background-color: $completegreen;
                                color: #fff;
                            }
                        }
                    }

                    .feed-box {
                        position: absolute;
                        bottom: 0;
                        padding: 4px;
                        text-align: center;
                        background-color: $lightblue;
                        width: 100%;
                        color: #fff;
						a{color: inherit}
						&.complete{
							background-color: $completegreen;
							height: 70%;                            
						}
						&.out, &.not{
							height: 35%;
						}
                    }
                    .editoccasion {
                        position: absolute;
                        bottom: -20%;
                        padding: 4px;
                        text-align: center;
                        background-color: $darkblue;
                        width: 100%;
                        color: #fff;
						a{color: inherit}
                    }					
                }

                .weekday {
                    position: relative;
                    width: 100%;
                    margin-right: 5px;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
					flex: 1 1 0; flex-grow: 1; flex-shrink: 1; flex-basis:0px;
					a{
						background-color: $lightblue;
						width: 100%;
						padding: 16px;
						color: inherit;
						display: block;
						transition: all .4s ease;
					}
                    &:hover a, &.active a, a.active{
                        background-color: $darkblue;
                        transition: all .4s ease;
                    }
					&.complete a, &.complete a:hover, &.active.complete a:hover{
						background-color: $completegreen !important;
                        position: relative;
                        .tick{
                            position: absolute;
                            right: floor(calc(($grid-gutter-width)/2));                           
                        }
					}
					&:last-of-type{
						margin-right: 0;
					}
					
                }
			
            }
			.completebtn{
				padding: 1rem 0;
				color: #fff;
				position: absolute;
				bottom: -60px;
				right: 15px;				
			}	
			.tab-content .days{
				margin-top: 0;
			}
		}
    }
    .button-cont {
        display: flex;
        width: 100%;
        padding: 18px 0;
		display: flex;
		justify-content: center;		
        &.pad1 {		
			border-top: 1px solid $lightgrey;
            padding: floor(calc(($grid-gutter-width)/2)) 0 floor(calc(($grid-gutter-width))) 0;
        }
        .continue {
           margin: 0;
           padding: 7px 20px;
           background:$lightblue;
           color: #fff;
           margin: 0 40px 0 0;
           display: flex;
           p {
               margin: 0;
               color: #ffffff !important;
           }
           img {
				width: 10px;
				align-self: center;
				margin: 0;
				margin-left: 10px;
				margin-right: -5px;
           }
        }
        .continue1 {
           margin: 0;
           padding: 7px 35px;
           background: $darkblue;
           color: #fff;
		   font-size: 1.4rem;
		   font-weight: normal;
           margin: 0 floor(calc(($grid-gutter-width / 2))); 
		   &:last-of-type{
				margin-right: 0;
		   }
           display: flex;
           img {
				width: 7px;
				align-self: center;
				margin: 0 10px;
           }
           a {
               color: #ffffff !important;
           }
           #p-right {
               margin-left: 10px;
           }		   
           #reverse {
				transform: rotate(180deg);
           }
           #p-right {
               margin-left: 10px;
           }
        }
    }
}

.header > .nav{

    width: 100%;
    height: 110px;
    display: block;

    .nav-inner{
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        height: 100%;
        margin: auto;
        .nav-items{
            z-index: 999;
            .nav-item{
                text-align: center;
                cursor: pointer;
                padding: .5em;
                transition: all .4s ease;
                span {
                    color: $lightblue;
                    display: block;
                }
                &.video-demo{
                    background: url('../../img/watch-the-demo.svg') no-repeat center center;
                    background-size: cover;
                    display: block;
                    width: 70px;
                    height: 54px;
                }
            }
        }
    }
/*
    .logo{
        display: inline-block;
		width: 201px;
		height: 147px;
		position: relative;
		top: 18px;
        z-index: 10;
        left: -20px;

        .fd{
            display: block;
            background: url('../../img/ffd-diary-logo.png') no-repeat center center;
            background-size: cover;
            width: 100%;
            height: 100%;
            max-width: 270px;
        }
    }
*/
    .nav-items{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 50%;
        color: $darkblue;
		z-index: 15;
        .nav-item{
            text-align: center;
            cursor: pointer;
            padding: .5em;
            transition: all .4s ease;

            &:hover, &.active{
                color: $lightblue;
                transition: all bottom .4s ease;
                .hover-svg, img {
                    fill: $darkblue;
					filter: invert(76%) sepia(81%) saturate(2841%) hue-rotate(214deg) brightness(98%) contrast(112%);
                }
            }

            &.video-demo{
                background: url('../../img/watch-the-demo.svg') no-repeat center center;
                background-size: cover;
                display: block;
                width: 72px;
                height: 55px;
            }

            .nav-icon {
                position: relative;
                display: block;
                height: 30px;
                width: 30px;
                margin: auto;
				.active img, &:hover img{
					fill: $darkblue;
					filter: invert(76%) sepia(81%) saturate(2841%) hue-rotate(214deg) brightness(98%) contrast(112%);
				}
            }
            .nav-icon1 {
                position: relative;
                display: block;
                height: 25px;
                margin: auto;
                margin-bottom: 5px;
                width: 30px;
            }
        }
    }  
}


.main{
	position: relative;
}

@import "graphics";

.content-area{
    position: relative;
    z-index: 50;	
    max-width: 1400px;
    margin: 0 auto;
    /*display: flex; // courses is screwed by this
    align-items: flex-start;*/

    .login-box{
        padding: 1em;
        background: rgb(234, 247, 255);
        display: flex;
        flex-flow: column wrap;

        button , .btn{
            width: fit-content;
            display: inline-block;
            font-size: 1.2em;
        }
		.login > a{float: right}
    }

    .section{
        /*flex: 3;*/
        .login-title {
            display: flex;
            .diary-icon {
                height: 60px;
                width: 60px;
                margin-right: 8px;
            }
            .main-title {
                margin: 0;
                align-self: center;
            }
        }
        
        h1{
            flex-basis: 100%;
            /*margin-bottom: 40px;*/
            color: $lightblue;
        }

        .desc{
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            .icon{
                flex: 1;
                .icon-img, img{
                    display: block;
                    margin: auto;
                    width: 70%;
                }
            }

            .copy{
                flex: 4;
                align-self: center;

                h2{
                    color: $lightblue;
                }

                p:not(:first-of-type){
                    margin: 16px 0;
                }
            }
        }
        .login .desc{
            flex-direction: row;
        }
        &.cols, &.row{
            display: flex;
            flex-flow: row wrap;
            .col, .col-md-6{
                h2 .caret{
                    display: block;
                    position: relative;              
                    &::after{
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) rotate(-90deg);
                        right: 20px;
                        width: 12px;
                        height: 6px;
                        background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23000;" /></svg>');
                        background-size: contain;
                        transition: transform 0.4s;                    
                    }
                }
                .accordion:checked {
                    ~ p, ~ ol, ~ li, ~ a {
                        display: inline-block !important;
                    }
                    + .diary-title + .content {
                        display: block !important;
                    }    
                }                  
                .accordion:checked ~ h2 .caret::after, .accordion:checked + .diary-title h2 .caret::after {
                  transform: translateY(-50%) rotate(0) !important;
                }                  
                #help {
                    position: absolute;
                    right: 68px;
                    font-size: 18px;
                    color: $darkblue;
                    margin-top: 8px;
                }
                .help-icon, #help-icon, #help-button img {
                    height: auto;
                    width: 36px;
                    position: absolute;
                    right: 25px;
					&:hover{cursor: pointer;}
                }
                .blue-box {
                    position: absolute;
                    background-color: $darkblue;
                    padding: 20px 30px 30px 30px;
                    width: 350px;
                    right: 55px;
                    margin-top: 42px;
                    display: none;
                    color: #fff;
                    z-index: 99;
                    h3 {
                        margin-bottom: 10px;
                    }
                }
                button, .btn {
                    padding: 7px 35px;
					position: relative;
					img{
						width: 7px;
						position: absolute;
						right: 18px;
						top: 11px;						
					}
                }
				.btn-link{
					background: none;
					padding: 8px 0;
					color: #000;
					&:focus{
						outline: none;
					}
				}
                ol {
                    margin-left: 18px;
                    font-weight: bold;
                    font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
                    li {
                        margin: 4px 0 4px 0;
                    }
                }
            }
        }
    }
}
.days-completed{
    /*position: absolute;
    top: 20px;
    right: 0%;
    transform: translateX(-50%);*/
}
/*
.section .days-completed{
	top: 0;
}
*/
.footer {
    display: flex;
	flex-direction: row;
    margin: floor(($grid-gutter-width)*2) auto floor(($grid-gutter-width));
    justify-content: center;
    padding-bottom: 60px;
    border-top: 1px solid $lightgrey;
	max-width: 900px;
	z-index: 5;
    hr {
        position: absolute;
        width: 100%;
    }
    #kantar {
        height: 24px;
        width: 100px;
        padding: 7px 15px 0 5px;
    }
    .footer-text, .footer-text a {
        font-size: 11px;
        align-self: center;
        padding: 7px 15px 0 0;
        color: #949698;

    }
}
// end content area

@media only screen and (max-width: 1250px) {

    .fd-input{
        width: 60%;
    }
    .main {
        .sub-wrapper {
            /*top: 200px;*/
            &.spine {
                .days1 {
                    top: -64px;
                    right: 112px;
                }

            }
        }
    }

}


@media only screen and (max-width: 1150px) {

    #drink1{
        left: 80px !important;
        top: 420px !important;
    }

    #fish{
        left: 90px !important;
    }

    .nav{
        .nav-items{
            width: 55%;
        }
    }


	#coffee{
		display: none;
	}

	#sandwich{
		display: none;
	}

}



@media only screen and (max-width: 950px) {


    .nav{
        .logo{
            left: 0;
            .fd{
                max-width: 180px;
                height: 79%;
            }
        
        }

        .modes{
            right: 0;
            .mode{
                img{
                    width: 75%;
                }
            }
        }

        .nav-inner{
                .nav-item {
                    &.video-demo {
                        top: 130px;
                        right: 25px;
                    }
                }
        }

    }

    .main {
        .sub-wrapper-menu {
            left: 50%;
            top: 270px;
        }
		/*
        .side-days {
            top: 200px;
            -webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);
			width: 350px;
			left: 50%;
        }
		*/
        .sub-wrapper {
            top: 40px;
            .diary {
                .days {
                    .weekday {
                        font-size: 0.65em;
                    }
                    .meal {
                        span {
                            padding: 0;
                        }
                    }
                }
		
            }
        }
    }

	.footer {
		min-width: 360px;
		width: 82%;
		top: 1280px;
		padding-bottom: 60px;
	}

	#kantar {
		height: 24px;
		width: auto;
		padding: 7px 4px 0 4px;
	}

}

@media only screen and (max-width: 510px) {

    .burger {
        position: absolute;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        z-index: 999;
        top: 20px;
        right: 15px;
        padding: 14px;
        display: block !important;
        z-index: 9999;
        cursor: pointer;
        // visibility: visible;
        // opacity: 0;
        transition: all .3s ease;
        &.close {
            transition: all .3s ease;
            span {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(7px, 7px);
                    transition: all .3s ease;
                }
                &:nth-child(2) {
                    opacity: 0;
                    transition: all .3s ease;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg) translate(7px, -7px);
                    transition: all .3s ease;
                }
            }
        }
        span {
            width: 100%;
            height: 5px;
            background-color: $lightblue;
            display: block;
            margin: 5px 0 5px 0;
            transition: all .3s ease;
            border-radius: 100px; 
        }
    }

    .footer {
        // position: fixed;
        // bottom: 0;
        // top: inherit;
        // z-index: 99;
        padding-bottom: 30px;
        top: 1620px;
        left: 0;
        -webkit-transform: translate(0%,0);
            transform: translate(0%,0);
        width: 100%;
        .footer-text {
            padding: 10px 0 0 10px;
        }
        #kantar {
            padding: 7px 3px 0 5px;
        }

    }



    #help-button {
        margin-top: 15px;
    }

    .content-area {
        .section {
            &.cols {
                .col {
                    .blue-box {
                        width: auto;
                        left: 25px;
                        right: 25px;
                    }
                }
            }
        }
        .graphic {
            display: none;
        }
        .login-box {
            button {
                font-size: 0.9em;
                margin-top: 15px;
            }
        }
    }

    #fish {
        left: 30px !important;
    }

    .main {
        .nav-items {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: #eaf7ff;
            height: 325px;
            z-index: 999;
            display: grid;
            grid-template-columns: auto auto;
            display: none;
            margin: 0;
            .nav-item {
                position: relative;
                width: 100%;
                color: $lightblue !important;
                span {
                    color: $lightblue !important;
                }
                &.video-demo {
                    position: absolute;
                    right: 100px;
                    top: 30px;
                    width: 60px;
                    height: 47px;
                    display: block !important;
                }
                .nav-icon {
                    height: 40px;
                    width: 40px;
                    display: block;
                }
            }
        }

        .nav {
            .modes {
                position: absolute;
                right: 20px;
                top: 155px;
                height: 28px;
            }
            .logo {
                position: absolute;
                top: 0;
                left: 10px;
                width: 282px;
            }
            .nav-inner{
                .nav-item {
                    &.video-demo {
                        top: 30px;
                        right: 96px;
                    }
                }
			}
        }

        .sub-left-img,
        .sub-right-img {
            display: none;
        }

        .sub-wrapper {
            position: relative;
            left: inherit;
            z-index: 99;
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
            &.spine {
                left: 0;
                float: right;

                .days1 {
                    position: relative;
                    top: initial;
                    margin-top: 12px;
                    right: 0;
                }
                .popup-1 {
                    .colflex1,
                    .colflex {
                        .left {
                           .edit {
                            right: 0;
                            align-self: center;
                           } 
                        }
                        .right {
                            .long-list {
                                margin: auto;
                                width: 200px;
                                margin-bottom: 3px;
                            }
                        }
                    }
                }						 
                &::before {
                    left: -52px;
                    width: 70px;
                    background-size: 85px;
                }
                #help {
                    right: inherit;
                    left: 0;
                    top: -34px;
                }
                #help-icon, .help-icon {
                    right: inherit;
                    left: initial;
                    right: 0;
                    top: -44px;
					&:hover{cursor: pointer}
                }
                .blue-box {
                    width: auto;
                    right: 20px;
                    left: 0;
                }
            }
            .diary {
                margin-bottom: 70px;
                .days-comp {
                    bottom: -20px;
                }
                .days {
                    .meal {
                        width: 100%;
                        margin-bottom: 0px;
                        min-height: 125px;
                        span {
                            /* font-size: 0.85em; */
                        }
                    }
                    .weekday {				
                        padding: 5px 0;							
                        font-size: 0.85em;
                    }
                }
                .day-title {
                    margin: 14px 0 14px 0;
                }
		
            }
			.colflex1,				
            .colflex {
                display: block;
                 &.flex {
                    display: flex !important;
                }
                .right {
                    margin-left: 0;
                    ol{
                        margin-left: 24px;
                    }
                }
                .left {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.modal-dialog {
	.modal-body{
		> ul{padding: 1.5rem;}
	}
	.btn{
		background: $darkblue;
		padding: 3px 8px 3px 8px;
		text-align: center;	
		margin: 0 floor(calc(($grid-gutter-width)/3));
		&:last-of-type {
		   margin-right: 0;
		}
	}
	.test {
		font-size: 48px;
		color: $lightblue;
		text-align: right;
		cursor: pointer;
	}
	.modal-footer{border-top: 0}
	.popup-head {
		width: 100%;
		background: $darkblue;
		padding: floor(calc(($grid-gutter-width)/3)) floor(calc(($grid-gutter-width)/2));
		color:#ffffff !important;
		#pu-right {
			float: right;
			text-align: right;
		}
        & ~ p {
            margin-left: floor(calc(($grid-gutter-width)/2));
            font-size: 0.875rem;
        }
	}
	.complete_header{
		padding-bottom: floor(calc(($grid-gutter-width)/2));
		margin-bottom: floor(calc(($grid-gutter-width)/6));
        margin-top: floor($grid-gutter-width);
        .back{
			position: relative;
			min-height: 1px;
			padding-top: floor(calc(($grid-gutter-width)/2));
			padding-bottom: floor(calc(($grid-gutter-width)/2));
			margin: 0;
			width: 100%;
		}
		&.row{
			padding-left: floor(calc(($grid-gutter-width)/3));
			padding-right: floor(calc(($grid-gutter-width)/3));
		}
		[class*='col-'] {
			padding-left: floor(calc(($grid-gutter-width)/6));
			padding-right: floor(calc(($grid-gutter-width)/6));
			text-align: center
		}
	}
	.complete_body{
		&.row{
			margin-bottom: floor(calc(($grid-gutter-width)/2));
		}
		
		[class*='col-'] {
			padding-left: floor(calc(($grid-gutter-width)/6));
			padding-right: floor(calc(($grid-gutter-width)/6));
			text-align: center;
			span{
				background-color: $lightgrey;
				display: block;
				padding-left: floor(calc(($grid-gutter-width)/2));
				padding-top: 2px;
				padding-bottom: 1px;
			}
			label{
				background-color: $lightgrey;
				display: block;
				&:hover{cursor: pointer}
				&.checked{background-color: $darkgrey;}
			}
			&:first-of-type{text-align: left}
		}
	}	
}

form{
	legend{
		width: auto;
        p {
            font-size: 0.875rem;
        }
	}
}

@media (min-width: 1200px){
	.modal-xl {
		max-width: 960px;
	}
}
#ajaxoverlayer {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 20000;
	&_wrapper {
		top: 0px;
		width: 100%;
		height: 100%;
		background: white;
		opacity: .50;
		z-index: 20001;
	}
	&_content {
	  position: absolute;
	  width: 200px;
	  top: 50%;
	  left: 50%;
	  margin: -80px 0 0 -100px;
	  background: white;
	  border: 1px solid black;
	  padding: 10px 20px;
	  opacity: none;
	  z-index: 20002;
	  font-size: 12px;
	  font-weight: bold;
      font-family: 'KantarBrown-Bold', Arial, Helvetica, sans-serif;
	}
}  

#page-index,
#page-login,
#page-contact_us,
#page-help,
#page-incentives,
#page-faqs{
	.content-area{background-color: rgba(255, 255, 255, 0.8);}
	&.darkmode .content-area {
		background-color: rgba(0, 0, 0, 0.2);
	}
}

@import "lightmode";

#page-help,
#page-faqs{
	.btn-link{
		background: transparent;
		padding-left: 0;
        text-align: left;
	}
}

/*
.col-md-1, .col-sm-1 {
	@extend .col-xs-1;
}
.col-md-2, .col-sm-2 {
	@extend .col-xs-2;
}
.col-md-3, .col-sm-3 {
	@extend .col-xs-3;
}
.col-md-4, .col-sm-4 {
	@extend .col-xs-4;
}
.col-md-5, .col-sm-5 {
	@extend .col-xs-5;
}
.col-md-6, .col-sm-6 {
	@extend .col-xs-6;
}
.col-md-7, .col-sm-7 {
	@extend .col-xs-7;
}
.col-md-8, .col-sm-8 {
	@extend .col-xs-8;
}
.col-md-9, .col-sm-9 {
	@extend .col-xs-9;
}
.col-md-10, .col-sm-10 {
	@extend .col-xs-10;
}
.col-md-11, .col-sm-11 {
	@extend .col-xs-11;
}
.col-md-12, .col-sm-12 {
	@extend .col-xs-12;
}
*/
@import "occattributes";
@import "occcourses";
@import "occdetails";
@import "wide";


.hidden {display:none !important;}
.hidden-if {display:none !important;}

.btn.float-end {
    margin-left: 10px;
}