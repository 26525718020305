#page-occasion_details {
    .header {
        height: 110px;
        > .nav {height: 110px}
        &::after{top:108px;}
    }   
    .main {
        margin-top: 110px;
        padding-top: 0;
    }
}

#page-faqs {
    .main {
        margin-top: 40px;
    }
}
div.card-header button {
    padding-right: 3em;
    &:before {
        content: ' ';
        --b: 4px;
        width: 10px;
        aspect-ratio: 1;
        background: conic-gradient(from 90deg at var(--b) var(--b), #FFF 90deg, #000 0) calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b)) calc(50% + var(--b));
        /*display: inline-block;*/
        float: right;
        margin-top: 4px;
        position: absolute;
        right: 0;

    }
}

.darkmode div.card-header:before {
    background: conic-gradient(from 90deg at var(--b) var(--b), #000 90deg, #fff 0) calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b)) calc(50% + var(--b));
}


#page-occasion_courses  {
    .sub-wrapper-menu {
        max-width: 350px;
        width: initial;
        .menu-container {
            max-width: 350px;
            width: initial;
        }    
    }
}
.modal-dialog .complete_header [class*=col-] {font-size: 0.85rem}

.mobile {
    .header > .nav .nav-items {
        justify-content: flex-start;
        .nav-link{padding-top: 0; padding-bottom: 0;}
    }
    .hidden-xs{display: none}
    .modal-dialog .popup-head{
        position: sticky;
    }
    .modal-dialog .tab_question .items{
        position: sticky;
    }
    &#page-occasion_attributes,
    &#page-occasion_courses,
    &#page-occasion_details{
        footer.footer{display: none}
    }
    
    &#page-occasion_details #questionpopup_2 .need_answers div:not(.headrow, .empty) div[class*=col-]:not(:first-of-type, .noinputs) {
        
        flex: 0 0 auto;
        width: 100%;
        padding: 0;
        text-align: left;
        label[for^=answer]:not([for*=whw]) {
            display: flex; flex-direction: row;
            input[type=checkbox]{display: inline-block; order: 1; margin: 4px 4px 0 0;}
            span { order: 2; margin: 4px 0 0 4px; }
        }    
    }
}

@media only screen and (max-width: 950px) {
    #page-occasion_details {
        .main .sub-wrapper {
            top: 0px; 
        }
    }
}

#page-occasion_details #product_content h3, 
#page-occasion_details #product_content .h3, 
#page-occasion_details #dish_content h3, 
#page-occasion_details #dish_content .h3 {
    font-size: 1rem;
}

.header {
    top: 0;
    z-index: 150;
    background: white;
    .diary-side{
        &.clone{
            top: 0px;
            position: absolute;
            right: 15px;
            width: 50%;
            .diarymealcomponentstitle{display: none}
            .diarymealcomponents{display: none}
            .nextcoursebtn{display: none}
            .completeoccasionbtn{display: none}
            .cancelbuttons{display: none}
            .indicator{
                &.filter-orange{
                    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
                }
            }
            h3{font-size: 1rem; max-width: 50%; min-height: 30px; }
            p{ font-size: 0.875rem;max-width: none;
                &:before{
                    content: '';
                    display: block;
                    float: right;
                    width: 50%;
                    height: 20px;
                }
            }
        }
    }
    .nav-inner input:checked ~ .nav-items {
        transform: none;
    }
    .nav-items{
        position: absolute;
        width: 300px !important;
        margin: -100px 0 0 0;
        padding: 50px;
        flex-direction: column;
        top: 20px;
        padding-top: 125px;
        z-index: 60!important;
        height: 100vh;
        background: #ededed;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);    
        .nav-item {
            span {
                position: relative; 
                background: transparent;    
                height: auto;
                width: auto;
                top: initial;
                font-size: 1.5rem;
            }
            .nav-icon {
                width: 2.5rem !important;
                height: auto!important;}
        }    
    }
    .nav-inner {
        display: block;
        width: auto;
        position: absolute;
        left: -10px;
        top: 0px;
        input {
            display: block;
            width: 33px;
            height: 26px;
            position: absolute;
            top: 10px;
            left: 15px;
            cursor: pointer;
            opacity: 0;
            z-index: 100;
            -webkit-touch-callout: none;
        }
        > span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: absolute;
            top: 15px;
            left: 15px;
            background: #00B6FF;
            border-radius: 3px;
            
            z-index: 99;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        opacity 0.55s ease;
            &:first-child{
                transform-origin: 0% 0%;
            }
            &:nth-of-type(2) {
                top: 25px;
                transform-origin: 0% 100%;
            }
            &:nth-of-type(3) {
                top: 35px;
                transform-origin: 0% 100%;
            }
        }
    }
    .nav-inner input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(0, -1px);
        background: #00B6FF;
    }
    .nav-inner input:checked ~ span:nth-of-type(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    .nav-inner input:checked ~ span:nth-of-type(3)  {
        transform: rotate(-45deg) translate(2px, -1px);
    }
    &> .nav {
        .modes{
            position: absolute;
            top: 46px;
            display: flex;
            flex-direction: column;
            width: 18%;
            height: 60px;
            left: 0px;
            justify-content: center;
            align-items: center;    
            .mode{
                margin: 0 0 5px;
                max-width: 100px;
                cursor: pointer;
                height: 35px;
                img{
                    display: block;
                    margin: auto;
                    width: 90%;
                    min-width: 70px;
                }
            }
        }
        .logo { 
            left: 80px;
            position: absolute;
            width: 131px;
            height: 97px;
            top: 0px !important;            
            .fd {
                display: block;
                background: url(../../img/new/family-foods-diary-logo-2.png) no-repeat center center;
                background-size: cover;
                width: 100%;
                height: 100%;
                max-width: 270px;
            }
        }
    }
}
.darkmode .header {
    background: black;
}    
.page-occasion_details .main{
    padding-top: 0 !important;
}
.mobile{
    .main {
        padding-top: 0px;
        margin-top: 50px;
    }
    .modal-footer {
        position: sticky;
        bottom: 0;
        width: 100%;
        z-index: 99;
        height: 66px;
        background: #fff;
    }
    &#page-login, &#page-index{
        .right {
            .desc {border-bottom: 1px solid #000;}
            > p, > ol, > ul, > a, div.content {display: none}
        }     
    }
    .row > .row {
        padding-left: 0;
        padding-right: 0;
    }
}
.main{
    padding-top: 0px;
    margin-top: 0px;

    .sub-wrapper {
        &.spine{
            margin: 0 auto 40px;
            background: rgb(251, 251, 251);  
            .row > .question-group {
                padding-right: calc(var(--bs-gutter-x));
                padding-left: calc(var(--bs-gutter-x));
            }         
        }
        .diary {
            .day-title{
                padding: 0 10px 0 0;
                justify-content: center;
                display: flex;
            }
            .form-horizontal{
                width:100%;
        
                .cat-vertical{
                    float: left;
                    width: 100%; 
                    .days{
                        flex-direction: column;
                        padding: 0 10px 0 0;
                        .catlabel{width: initial}
                    }
                }
            }
            .days {
                display: block;
                
                .meal {
                    display: flex;
                    flex-direction: row;
                    padding: 0;
                    min-height: auto;
                    font-size: 1rem;
                    
                    .catlabel{
                        padding: 0.5rem;
                        margin-right: 10px;
                        flex: 1 1 66%;
                        text-align: left;  
                        background-color: $lightgrey;
                        color: $black;
                    }
                    .feed-box, .editoccasion{
                        flex: 1 1 33%;
                        padding: 1rem;
                        position: initial;

                    }
                    .feed-box.complete{
                        display: none;
                    }
                }    
            }
            .days.tab-clicked {
                display: none;
            }
            .days-comp, .days-sub {
                top: 0;
                position: relative;
                bottom: initial;
                right: initial;
            }
            #occasions {
                /*display: none;*/
                &.row {
                    flex-direction: row;
                }
                .cat-vertical .meal {
                    min-height: initial !important;
                    padding: 8px;
                    .catlabel{
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: left;
                        span{
                            text-align: left;
                            width: 60%;
                        }
                    }
                    input {margin-top: 5px;}
                    span{ &:before{content: ''}}
                }
                .tabs-controls {
                    position: absolute;
                    right: 1.5rem;
                    z-index: 50;               
                    a{
                        height: 3rem; 
                        display: inline-block;
                        width: 1.5rem;
                        &::before {
                            border-style: solid;
                            border-width: 0.25em 0.25em 0 0;
                            content: '';
                            display: inline-block;
                            height: 1.5em;
                            left: 0.15em;
                            position: relative;
                            top: 0.15em;
                            transform: rotate(-45deg);
                            vertical-align: top;
                            width: 1.5em;
                            color: #0060FF;
                        }
                    
                        &.next:before {
                            left: -0.5rem;
                            transform: rotate(45deg);
                        }
                        &.prev:before {
                            left: 0.25em;
                            transform: rotate(-135deg);
                        }
                    }
                }    
            }   
            #occasions.tab-clicked {
                display: block;
            }   
        }    
    }
}
.modal {
    overflow: hidden;
    .modal-dialog{
        overflow: hidden;
    }    
    .modal-body {
        max-height: calc(100vh - 100px);
    }
    .modal-footer {
        justify-content: center;        
        max-height: 10%;
        > * {
            margin: 0.25rem auto!important;
        }
    }    
}
.d-none { display: none !important; }
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-table { display: table !important; }
.d-table-cell { display: table-cell !important; }
.d-flex { display: -webkit-box !important; display: -webkit-flex !important; display: -moz-flex !important; display: -ms-flexbox !important; display: flex !important; min-width: 0; }
.d-flex-column { flex-direction: column }
.d-flex-row { flex-direction: row; }
.d-inline-flex { display: -webkit-inline-box !important; display: -webkit-inline-flex !important; display: -moz-inline-flex !important; display: -ms-inline-flexbox !important; display: inline-flex !important; }

@media (min-width: 768px) { 
  .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: -webkit-box !important; display: -webkit-flex !important; display: -moz-flex !important; display: -ms-flexbox !important; display: flex !important; min-width: 0; }
  .d-sm-flex-column { flex-direction: column }
  .d-sm-flex-row { flex-direction: row; }
  .d-sm-inline-flex { display: -webkit-inline-box !important; display: -webkit-inline-flex !important; display: -moz-inline-flex !important; display: -ms-inline-flexbox !important; display: inline-flex !important; } }

@media (min-width: 1002px) { 
  .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: -webkit-box !important; display: -webkit-flex !important; display: -moz-flex !important; display: -ms-flexbox !important; display: flex !important; min-width: 0; }
  .d-md-flex-column { flex-direction: column }
  .d-md-flex-row { flex-direction: row; }
  .d-md-inline-flex { display: -webkit-inline-box !important; display: -webkit-inline-flex !important; display: -moz-inline-flex !important; display: -ms-inline-flexbox !important; display: inline-flex !important; } }

@media (min-width: 1200px) { 
  .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: -webkit-box !important; display: -webkit-flex !important; display: -moz-flex !important; display: -ms-flexbox !important; display: flex !important; min-width: 0; }
  .d-lg-flex-column { flex-direction: column }
  .d-lg-flex-row { flex-direction: row; }
  .d-lg-inline-flex { display: -webkit-inline-box !important; display: -webkit-inline-flex !important; display: -moz-inline-flex !important; display: -ms-inline-flexbox !important; display: inline-flex !important; } 
}
.container {max-width: 100%;}
aside.graphic{display: none}
.days-completed{display: none}
/* .diary-title{display: none} */
.help, #help-button{display: none} // [class^='help'],

#page-occasion_courses, #page-occasion_details, #page-occasion_attributes {
    .header > .nav .logo {
        left: 60px;
        position: absolute; 
        width: 61px;
        height: 44px;               
    }  
} 

.mobile .header{
    height: initial;
    min-height: 110px;
    margin-bottom: 10px;
}

.side-days.clone {
    position: absolute;
    top: 0px;
    /*right: 15PX;*/
    width: 220px;
    float: right;
    margin-left: 40%;
    h3{font-size: calc(1rem + 0.6vw); max-width: 50%; min-height: 30px; }
    p{
        max-width: none;
        &:before {
            content: '';
            display: block;
            float: right;
            width: 50%;
            height: 20px;
        }
    }    
}
body#page-occasion_courses .menu-title p {
    color: white;
    font-size: 1rem;
}   
@media (pointer: fine) {
    .modal-dialog .complete_header [class*=col-] {font-size: 1rem}
    .header{
        position: initial;
    }
    .modal {
        .modal-footer {
            align-items: flex-end;        
        }    
    }
    #page-occasion_attributes,
    #page-occasion_courses,
    #page-occasion_details{
        .header > .nav .logo {
            width: 114px !important;
        }
    }
    #page-occasion_details{
        .side-days:before {
            background-color: #fff;
            -webkit-mask-image: url("../../img/mobile/pot.svg");
            mask-image: url("../../img/mobile/pot.svg");
            content: attr(data-text);
            position: absolute;
            right: 10px;
            top: 30px;
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            display: block;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            color: black;
            padding: 5px 0 5px 20px;
        }
    }
    .container {max-width: 1200px;}
    .d-sm-none { display: none !important; }
    .d-sm-flex { display: -webkit-box !important; display: -webkit-flex !important; display: -moz-flex !important; display: -ms-flexbox !important; display: flex !important; min-width: 0; }
    aside.graphic{display: inline}
    .days-completed{display: block; }
    .diary-title{display: flex}
    //[class^='help'], #help-button{display: flex; flex-wrap: wrap;}
    [class^='help'], #help-button{display: block;}
    #ajaxTreeView{flex-direction: column}
    #page-occasion_details #product_content h3, 
    #page-occasion_details #product_content .h3, 
    #page-occasion_details #dish_content h3, 
    #page-occasion_details #dish_content .h3 {
        font-size: 1.87rem;
    }
    #page-occasion_details #product_content .mobile{display: none}
    .main{
        margin-top: 40px;
        .button-cont {
            justify-content: flex-end;
        }    
        .sub-wrapper {
            margin-left: 15px;
            .diary{
                width: 100%;
                padding: 10px floor(($grid-gutter-width)) floor(calc(($grid-gutter-width)*4)) 10px;
                .days-comp, .days-sub{text-align: right;}
                .days{
                    display: flex;
                    padding-left: 0;
                    &.tab-clicked {
                        display: flex;
                    }
                    .meal {
                        font-size: 1rem;
                        
                    }
                }
                #occasions {
                    display: flex;
                    &.row {
                        flex-direction: row;
                    }
                    .tabs-controls {display: none}
                    > .active{width: 100%}
                    div[id^=weekday].offset-md-3 {
                        float: none !important;
                    }
                    .cat-vertical {
                        width: initial;
                        .meal {
                            min-height: initial !important;
                            padding: 8px;
                            .catlabel{
                                display: flex;
                                flex-direction: row-reverse;
                                justify-content: left;
                                span {
                                    min-width: 50%;
                                    text-align: left;
                                }
                            }
                            input {margin-top: 5px;}
                            span{ &:before{content: ''}}
                        }
                    }
                    .meal{
                        /*font-size:0.65rem;*/
                        background-color: $lightgrey;
                        flex-direction: column;
                        text-align: center;
                        min-height: 180px;
                        padding-top: 8px;  
                        .feed-box{
                            display: block;
                            position: absolute !important;
                            &.complete{display: block}
                        } 
                        .catlabel{
                            text-align: center;
                            color: $link-color;
                            padding: initial;
                            margin-right: 0;
                            span{padding: 0;}
                            &.complete{
                                background-color: $lightgrey;
                            }
                        } 
                        .editoccasion{
                            position: absolute;
                            padding: 4px;
                        }                                     
                    }
                    .days-comp, .days-sub{
                        top: floor(calc(($grid-gutter-width)*3));
                    }
                }   
            }            
            &.spine {
                box-shadow: 4px 6px 15px 0px $shadowcolor;
                min-width: 950px;
                margin: 0 auto 0;           
                background: rgb(251, 251, 251);                
                &::before {
                    content: '';
                    position: absolute;
                    left: -90px;
                    top: 0;
                    bottom: 0;
                    width: 125px;
                    height: auto;
                    z-index: -1;
                    background: url('../../img/spine1.png');
                    background-repeat: repeat-y;
                    // background-repeat: no-repeat space;
                    box-shadow: 3px 28px 15px -22px $shadowcolor;
                    background-size: 150px;
                    display: block;
                 }
            }
        }        
    }
    .darkmode .main .sub-wrapper.spine{
        background: black;
        color: white;
    }
    .form-row{
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;
        width: 100%;
        &.form-group {
            margin-bottom: 1rem;
        }
        & > .row{
            margin-right: -5px;
            margin-left: -5px;	
        }
        & > .col, & > [class*=col-] {
            padding-right: 5px;
            padding-left: 5px;
        }
        .form-check-label{
            margin-bottom: 0;
            font-weight: normal;
            font-size: 1.1rem;
        }	
        
        .form-control{width: 100%}
    }
    .content-area {
        .section {
            &.cols {
                margin-top: 50px;
                padding-left: 40px;
            }
        }
    }            

    body{
        min-width: 1040px;
    }
    .header{
        .nav-inner {
            > input, > span{
                display: none
            }
        }
        .nav-items{
            position: absolute;
            flex-direction: row;
            top: 0px;
            margin: 0;
            padding: 0;
            height: initial;
            right: 0;
            transform: translate(0%, 0);
            background: transparent;
            width: 800px !important;
            width: calc(100% - 390px)!important; 
            .nav-item {
                span {
                    position: relative; background: transparent;    height: auto;
                    width: auto;
                    top: initial;left: initial;
                    font-size: 1rem;}
                .nav-icon {
                    width: 70px !important;
                    height: 60px !important;
                }
            }                
        }
        &::after {
            min-width: 1040px;
        }    
        &> .nav {
            .modes{
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                flex-direction: column;
                width: 15%;
                height: 100%;  
            }
            .logo {
                left: 165px !important;
                width: 209px !important;
                height: 153px !important;                
                top: 0px !important;
                z-index: 12;
                .fd {
                    display: block;
                    background: url(../../img/new/ffd-diary-logo-2.png) no-repeat top center;
                    background-size: contain;
                    width: 100%;
                    height: 100%;
                    max-width: 270px;
                }
            }
        }
    }    
}    
